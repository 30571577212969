Object.defineProperty(exports, "__esModule", {
  value: true,
});

const configJSON = require("../../../framework/src/config");

exports.primaryAppColor = "Primary App Color";
exports.appSettingsHint = "In this section you can provide primary app color and deep linking configuration.";

exports.readAppRequirementsApiEndpoint =
  "/admin/v1/app_submission_requirements";
exports.readAppRequirementsApiMethodType = "GET";
exports.readQrCodesApiEndpoint = "/admin/v1/qr_codes";
exports.readQrCodesApiMethodType = "GET";
exports.saveChangesApiEndpoint = "/admin/v1/app_submission_requirements";
exports.saveChangesApiMethodType = "PUT";
exports.getStatesApiEndpoint = "/admin/v1/locations/countries";
exports.getStatesApiMethodType = "GET";
exports.getCitiesApiEndpoint = "/admin/v1/locations/states";
exports.getCitiesApiMethodType = "GET";
exports.editQrCodeApiEndpoint = "/admin/v1/qr_codes";
exports.editQrCodeApiMethodType = "PUT";
exports.createQrCodeApiEndpoint = "/admin/v1/qr_codes";
exports.createQrCodeApiMethodType = "POST";
exports.deleteQrCodeApiEndpoint = "/admin/v1/qr_codes";
exports.deleteQrCodeApiMethodType = "DELETE";

exports.getCountriesApiEndpoint = "/admin/v1/locations/countries";
exports.getCountriesApiMethodType = "GET";

exports.thisFieldIsRequired = "This field is required";
exports.maximum_30Characters = "Maximum 30 characters";
exports.maximum_50Characters = "Maximum 50 characters";
exports.maximum_170Characters = "Maximum 170 characters";
exports.maximum_4000Characters = "Maximum 4000 characters";
exports.emailMustBeInValidFormat = "Email must be in valid format.";
exports.appIconIsRequired = "App icon is required";
exports.commonFeatureBannerIsRequired = "Common feature banner is required";
exports.defaultPriceHasToBeMoreThanZero =
  "Default price has to be more than zero";

exports.appName = "App name";
exports.promotionalText = "Promotional Text";
exports.description = "Description";
exports.appIcon = "App Icon";
exports.commonFeatureBanner = "Common feature banner";
exports.website = "Website";
exports.email = "Email";
exports.phone = "Phone";
exports.firstName = "First Name";
exports.lastName = "Last Name";
exports.country = "Country";
exports.state = "State";
exports.city = "City";
exports.cityState = "City / State";
exports.postcodeZipPin = "Postcode / ZIP / Pin";
exports.addressLine_1 = "Address line 1";
exports.addressLine_2 = "Address line 2";
exports.privacyPolicyUrl = "Privacy policy url";
exports.supportUrl = "Support url";
exports.marketingUrl = "Marketing url";
exports.termsAndConditionsUrl = "Terms and conditions url";
exports.targetAudienceAndContent = "Target audience and content";
exports.distributedCountries = "Distributed countries";
exports.autoPriceConversion = "Auto price conversion";
exports.androidWear = "Android wear";
exports.googlePlayForEducation = "Google play for education";
exports.usExportLaws = "US export laws";
exports.copyright = "Copyright";
exports.appType = "App Type";
exports.featureGraphic = "Feature Graphic";
exports.productTitle = "Product Title";
exports.appCategory = "App Category";
exports.reviewUsername = "Review Username";
exports.reviewPassword = "Review Password";
exports.reviewNotes = "Review Notes";
exports.screenshots = "Screenshots";
exports.defaultPrice = "Default Price";
exports.tags = "Tags";
exports.deleteAppType = "Delete App Type";
exports.remove = "Remove";

exports.enterTheCountryPlaceholder = "Enter the country";
exports.enterTheStatePlaceholder = "Enter the state";
exports.enterTheCityPlaceholder = "Enter the city";
exports.enterTheCityStatePlaceholder = "Enter the city / state";
exports.enterThePostcodePlaceholder = "Enter the postcode";
exports.enterTheAddressDetailsPlaceholder = "Enter the address details";
exports.enterExtraAddressDetailsPlaceholder = "Enter extra address details";
exports.selectAudiencePlaceholder = "Select audience";
exports.mobileAppTitle = "Details";

exports.contactDetails = "Contact details";
exports.pageUrLs = "Page URLs";
exports.pricingAndDistribution = "Pricing and distribution";
exports.appCategory = "App category";

exports.label5AndUnder = "5 and under";
exports.label6_8 = "6-8";
exports.label9_12 = "9-12";
exports.label13_15 = "13-15";
exports.label16_17 = "16-17";
exports.label18AndOver = "18 and over";
exports.labelFreeApp = "Free app";
exports.labelPaidApp = "Paid app";
exports.labelAndroid = "Android";
exports.labelIOs = "iOS";

exports.addAppType = "Add app type";
exports.urlGetValidations = "urlGetValidations";
exports.mobileApp = "Mobile app";

exports.maximumTotalImageSizeCannotBeGreaterThan_50Mb =
  "Maximum total image size cannot be greater than 50MB";

exports.sampleDocuments = "Sample documents";
exports.sampleDocumentsHint =
  "Download these samples documents to help you fill the form";
exports.sampleDocumentsLink =
  "https://intercom.help/engineerai/en/articles/6465764-ios-sample-document";
exports.iOsSampleDocument = "iOS sample document";

exports.sampleDocumentsLink2 =
  "https://intercom.help/engineerai/en/articles/6465768-google-play-sample-document";
exports.googlePlaySampleDocument = "Google play sample document";

exports.headerTabs = "iOS sample document";
exports.mobileAppTab1Hint =
  "Here's where you add the info needed to submit your app to Apple’s App Store and Google's Play Store.";
exports.mobileAppTab2Hint =
  "Generate a QR code here - this lets customers download your store’s app.";

exports.qrCode = "QR code";
exports.updatedAt = "Updated at";
exports.editQrCode = "Edit QR code";
exports.deleteQrCode = "Delete QR code";
exports.appType = "App type";
exports.createQrCode = "Create QR code";

exports.errorOnInitialization = "Error on initialization";
exports.errorOnSave = "Error on save";
exports.errorOnDelete = "Error on delete";
exports.qrCodeGenerateError = "Qr Code Generate Error";
exports.qrCodeEditError = "Qr Code Edit Error";
exports.somethingWentWrong = "Something went wrong!";
exports.downloadJson = "Download JSON Settings";
exports.webJson = "Web JSON";
exports.mobileJson = "Mobile JSON";
exports.webJsonLink = `${configJSON.baseURL}/admin/v1/brand_settings/web_json`;
exports.mobileJsonLink = `${configJSON.baseURL}/admin/v1/brand_settings/mobile_json`;
exports.downloadJsonMethodType = "GET";
exports.headerTabNames = [
  { tab_name: "App Settings" },
  { tab_name: "App Requirement" },
  { tab_name: "Generate QR Code" }
];
exports.currentlyThereIsNoQrCodeGenerated = "Currently there are no QR codes generated. Please generate QR code.";
exports.deepLinkingConfiguration = "Deep Linking Configuration";
exports.appNameLabel = "App Name";
exports.googlePlayStoreURLLabel = "Google Play Store URL";
exports.appStoreURLLabel = "App Store URL";
// Customizable Area End
