// @ts-nocheck
import React, { useRef } from "react"
import NotificationsController from "./NotificationsController"
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web"
import TableComponent from "../../studio-store-ecommerce-components/src/TableComponent/TableComponent"
import { Dialog } from "../../studio-store-ecommerce-components/src/Dialog/Dialog"
import "./styles.css"
import TextField from "@material-ui/core/TextField"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import Pagination from "../../studio-store-ecommerce-components/src/Pagination/Pagination"
import { Scrollbars } from "react-custom-scrollbars"
import { withDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog"
import withHeaderBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web"

const titles = ["#", "Title", "Message"]
const configJSON = require("./config")
export class Notifications extends NotificationsController {
  renderEmptyPage(showEmptyPage: boolean) {
    return showEmptyPage ? (
      <NoProductCard onClick={this.handleCreateClick} />
    ) : (
        <div />
      )
  }
  render() {
    const {
      allNotifications,
      showNotificationCreate,
      totalCount,
      pageInitialized,
      checkedNotificationList,
      showEmptyPage
    } = this.state
    if (!pageInitialized) {
      return null
    }
    return (
      <Scrollbars ref={this.scrollRef}>
        <div className="business-notifications">
          {allNotifications?.length > 0 ? (
            <>
              <Header
                onClick={this.handleCreateClick}
                checkedNotificationList={checkedNotificationList}
                handleDeleteModal={this.handleDeleteModal}
              />

              <div className="notifications">
                <TableComponent
                  titles={titles}
                  data={allNotifications}
                  edit
                  push
                  handleEditClick={(item) => this.handleEditClick(item)}
                  handlePushClick={(item) => this.handlePushClick(item)}
                  getCheckedItems={(item) => this.checkedItems(item)}
                  checkbox
                />
                <div className="pagination-wrapper">
                  <Pagination
                    count={totalCount}
                    onChange={this.handlePageChange}
                    itemText={"notifications"}
                    pageSize={this.state.per_page}
                  />
                </div>
              </div>
            </>
          ) : (
            this.renderEmptyPage(showEmptyPage)
          )}
          {showNotificationCreate && (
            <CreateDialog
              updateField={this.updateField}
              onCreate={
                this.state.isEditClicked
                  ? this.handleEditNotification
                  : this.handleCreateNotification
              }
              editClicked={this.state.isEditClicked}
              notificationItem={this.state.notificationItem}
            />
          )}
        </div>
      </Scrollbars>
    )
  }
}

interface HeaderProps {
  children?: JSX.Element
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleDeleteModal: () => void
  checkedNotificationList: []
}

const Header = ({ children, onClick, checkedNotificationList, handleDeleteModal }: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <section className="d-flex align-items-center">
          <span className="header-title">{configJSON.notificationsHeader}</span>
          {checkedNotificationList?.length > 0 && (
            <p className="delete-items" onClick={handleDeleteModal}>
              {configJSON.deleteNotifications}
            </p>
          )}
        </section>

        <div className="header-buttons">
          <button className="create-button" onClick={onClick}>
            {configJSON.createNewNotification}
          </button>
        </div>
      </div>
      {children}
    </div>
  )
}

const NoProductCard = ({ onClick }: { onClick: any }) => {
  return (
    <div className="no-product-card">
      <div className="no-product-card-content">
        <span className="no-product-card-title">{configJSON.youHaveNoNotifications}</span>
        <span className="no-product-card-hint">
          {configJSON.youHaveNotSetUpAnyNotificationMessages}
        </span>
        <span className="no-product-card-hint">{configJSON.notificationMessage}</span>
        <button onClick={onClick} className="no-product-button">
          {configJSON.createNewNotification}
        </button>
      </div>
    </div>
  )
}

interface CreateDialogTypes {
  updateField: (name: string, value: any) => void;
  onCreate: any
  editClicked: boolean
  notificationItem?:  { id: number | string, title: string, message: string };
}

const Schema = Yup.object().shape({
  title: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(65, configJSON.titleMustBeMaximum_65Characters),
  message: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(178, configJSON.messageMustBeMaximum_178Characters),
})

const CreateDialog = ({ updateField, onCreate, editClicked, notificationItem }: CreateDialogTypes) => {
  const formRef = useRef(null)

  const setOpen = (newState: boolean) => {
    updateField("showNotificationCreate", newState)
  }

  const handleSubmit = (values: any) => {
    onCreate(!editClicked ? values : { ...values, id: notificationItem.id })
  }

  const handleDialogSubmit = () => {
    if (formRef.current) {
      // @ts-ignore
      formRef.current.handleSubmit()
    }
    return false
  }

  return (
    <Dialog
      minWidth="684px"
      open={true}
      setOpen={setOpen}
      onSubmit={handleDialogSubmit}
      title={
        (!editClicked ? configJSON.createLabel : configJSON.editLabel) +
        " " +
        configJSON.notificationLabel
      }
      okay={configJSON.saveLabel}
      containerClassName="notification-create-dialog"
      titleClassName="notification-create-title"
      customContent>
      <Formik
        innerRef={formRef}
        initialValues={{
          title: editClicked ? notificationItem.title : "",
          message: editClicked ? notificationItem.message : "",
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}>
        {(formikProps) => {
          const { values, errors, touched, handleChange } = formikProps

          return (
            // @ts-ignore
            <Form autoComplete="off" noValidate>
              <div className="content">
                <span className="input-title">{configJSON.titleLabel} *</span>
                <TextField
                  variant="outlined"
                  placeholder={configJSON.titlePlaceholder}
                  className="notification-create-input"
                  onChange={handleChange}
                  name="title"
                  value={values.title}
                  error={!!(errors?.title && touched?.title)}
                  helperText={errors?.title && touched?.title ? errors?.title : ""}
                  required
                />

                <span className="input-title">{configJSON.messageLabel} *</span>
                <TextField
                  variant="outlined"
                  className="notification-create-input"
                  placeholder={configJSON.messagePlaceholder}
                  onChange={handleChange}
                  name="message"
                  value={values.message}
                  error={!!(errors?.message && touched?.message)}
                  helperText={errors?.message && touched?.message ? errors?.message : ""}
                  required
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default withLoader(withDialog(withHeaderBar(Notifications)))
