import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PaymentCardController from "./PaymentCardController.web";
const configJSON = require("../config.js");
const razorpayIcon = require("../assets/razorpay.svg");
const stripeIcon = require("../assets/stripe.svg");
const clipboardIcon = require("../assets/clipboard.svg");

type stringNullTypes = string | null | undefined;

export class PaymentCard extends PaymentCardController {
  handlePaymentInfo = (
    api_key: stringNullTypes,
    handleClipboard: any,
    api_secret_key: stringNullTypes
  ) => {
    return (
      <>
        <div className="payment-card-card-information">
          <span className="title">{configJSON.user}</span>
          <span className="information">{api_key}</span>
          <IconButton className="clipboard">
            <img
              data-testid="copy-clipboard-test-user-btn"
              src={clipboardIcon}
              onClick={handleClipboard(api_key)}
            />
          </IconButton>
        </div>
        <div className="payment-card-card-information">
          <span className="title">{configJSON.password}</span>
          <span className="information">{configJSON.dummyPassword}</span>
          <IconButton
            data-testid="copy-clipboard-test-password-btn"
            className="clipboard"
            onClick={handleClipboard(api_secret_key)}
          >
            <img src={clipboardIcon} />
          </IconButton>
        </div>
      </>
    );
  };

  handleActivePaymentCard = (active: any, razorpayAccountStatus: any) => {
    if (!active) {
      return (
        <div className={"payment-card-inactive"}>{configJSON.notActive}</div>
      );
    } else {
      return (
        <div
          className={
            razorpayAccountStatus
              ? "payment-card-active-razor"
              : "payment-card-active"
          }
          style={{ backgroundColor: `${razorpayAccountStatus?.color_code}` }}
        >
          {razorpayAccountStatus
            ? razorpayAccountStatus?.status
            : configJSON.active}
        </div>
      );
    }
  };

  handleRazorpayAccountId = (
    razorpay_account_id: any,
    isApiKeySet: any,
    handleClick: any
  ) => {
    if (razorpay_account_id) {
      return (
        <Button
          target="_blank"
          href={configJSON.viewDashboardLink}
          className="card-button"
          onClick={handleClick}
        >
          {configJSON.viewDashboard}
        </Button>
      );
    } else {
      return (
        <Button className="card-button" onClick={handleClick}>
          {isApiKeySet ? configJSON.activateAccount : configJSON.editPayment}
        </Button>
      );
    }
  };

  handlePaymentCardDetails = ({
    active,
    razorpayAccountStatus,
    configuration_type,
    data,
    api_key,
    api_secret_key,
    razorpay_account_id,
    isApiKeySet,
    paymentConfigType,
  }: {
    active: any;
    razorpayAccountStatus: any;
    configuration_type: any;
    data: any;
    api_key: stringNullTypes;
    api_secret_key: stringNullTypes;
    razorpay_account_id: any;
    isApiKeySet: any;
    paymentConfigType: (
      arg0: any,
      arg1: any
    ) =>
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
  }) => {
    return (
      <div className="payment-card-details">
        <div className="payment-card-information">
          {this.handleActivePaymentCard(active, razorpayAccountStatus)}
          <div className="payment-card-configuration-type">
            {paymentConfigType(configuration_type, data)}
          </div>
          {active ? (
            this.handlePaymentInfo(
              api_key,
              this.handleClipboard,
              api_secret_key
            )
          ) : (
            <div className="payment-card-card-not-active">
              {configJSON.useToGetMessage}
            </div>
          )}
        </div>
        {this.handleRazorpayAccountId(
          razorpay_account_id,
          isApiKeySet,
          this.handleClick
        )}
      </div>
    );
  };

  paymentConfigType = (configuration_type: stringNullTypes, data: any) => {
    let title: any;
    if (data && configuration_type === "razorpay") {
      title = configJSON.razorpayTitle;
    } else {
      if (configuration_type === "stripe") {
        title = configJSON.stripeTitle;
      } else {
        title = configJSON.paymentTitle;
      }
    }
    return title;
  };

  render() {
    const { data, razorpayAccountStatus } = this.props;
    const {
      id,
      api_secret_key,
      api_key,
      configuration_type,
      razorpay_variables,
      razorpay_account_id,
    } = data || {};
    const active = razorpay_variables || (id && api_key && api_key !== "n/a");
    const isApiKeySet =
      !api_key ||
      !api_secret_key ||
      api_key === "n/a" ||
      api_secret_key === "n/a";

    let gateWayIcon;
    if (configuration_type === "razorpay") {
      gateWayIcon = razorpayIcon;
    } else if (configuration_type === "stripe") {
      gateWayIcon = stripeIcon;
    } else {
      gateWayIcon = "";
    }

    return (
      <div className="payment-card-vertical">
        <div className="card-image">
          <img src={data ? gateWayIcon : ""} className="payment-card-type" />
        </div>
        {this.handlePaymentCardDetails({
          active,
          razorpayAccountStatus,
          configuration_type,
          data,
          api_key,
          api_secret_key,
          razorpay_account_id,
          isApiKeySet,
          paymentConfigType: this.paymentConfigType,
        })}
      </div>
    );
  }
}
