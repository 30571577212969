import React from "react";
import ImageCatalogueBulkUploadController from "./ImageCatalogueBulkUploadController";
import "./styles.css";
import "../styles.css";
import withHeaderActionBar from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import withLoader from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import UploadBox from "../../../studio-store-ecommerce-components/src/UploadBox/UploadBox.Web";
import Button from "@material-ui/core/Button";
import { withDialog } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import { Scrollbars } from "react-custom-scrollbars";
export const configJSON = require("../config.js");

export class ImageCatalogueBulkUpload extends ImageCatalogueBulkUploadController {
  render() {
    return (
      <Scrollbars>
        <div className="image-catalogue image-catalogue-bulk-upload">
          <Header onBackButtonClick={this.handleBackButtonClick} />
          <div className="content">
            <div className="image-upload-card">
              <span className="image-upload-header">{configJSON.imageDetails}</span>
              <span className="image-upload-hint-total">
                {configJSON.bulkUploadMessage}
              </span>
              <span className="image-upload-image-list-header">{configJSON.image}</span>
              <span className="image-upload-image-list-hint">
                {configJSON.bulkUploadHint}
              </span>
              {this.state.selectedImages.length ? (
                <div className="image-upload-selected-images">
                  {this.state.selectedImages.map((i, index) => (
                    <div className="image-upload-selected-image-wrapper"
                      key={index}>
                      <img
                        className="image-upload-selected-image"
                        alt="selected image"
                        src={i}
                      />
                      <Button
                        className="image-upload-selected-image-remove-basic"
                        onClick={this.handleDeleteSelectedImage(index)}
                      >
                        {configJSON.remove}
                      </Button>
                    </div>
                  ))}
                  <div style={{ paddingBottom: 32, display: "flex" }}>
                    <UploadBox
                      uploadIcon={require("../assets/add.svg")}
                      height="155px"
                      minHeight="120px"
                      width="155px"
                      margin="0"
                      multiple
                      uploadMsg={
                        <div className="image-upload-message">{configJSON.addImage}</div>
                      }
                      sizeMsg={
                        <div className="image-upload-size-hint">{configJSON.maxMb}</div>
                      }
                      messageMargin="0"
                      onSelectFile={this.handleSelectImages.bind(this)}
                    />
                  </div>
                </div>
              ) : (
                <UploadBox
                  uploadIcon={require("../assets/add.svg")}
                  height="180px"
                  width="100%"
                  margin="8px 0 20px 0"
                  multiple
                  uploadMsg={
                    <div className="image-upload-message">{configJSON.addImage}</div>
                  }
                  sizeMsg={
                    <div className="image-upload-size-hint">{configJSON.maxMb}</div>
                  }
                  messageMargin="0"
                  onSelectFile={this.handleSelectImages.bind(this)}
                />
              )}
            </div>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

interface HeaderProps {
  onBackButtonClick: any;
}

export const Header = ({ onBackButtonClick }: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <div>
          <div className="icon-back-wrapper" onClick={onBackButtonClick} data-testid="bulk-upload-back-button">
            <i className="icon-arrow-right" onClick={onBackButtonClick} />
            <span>{configJSON.back}</span>
          </div>
          <div className="header-title">{configJSON.productImage}</div>
        </div>
      </div>
    </div>
  );
};

export default withDialog(
  withHeaderActionBar(withLoader(ImageCatalogueBulkUpload))
);
