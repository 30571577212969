import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { EmailSettingsMap, Tab } from "../Types";
import { Endpoint } from "../EmailTemplatesController";
import { withHeadeActionBarProps } from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { DialogProps } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    match: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  selectedTab: number;
  headerTabs: Tab[];
  emailSettings: EmailSettingsMap;
  content?: string;
  contentPlain?: string;
  title: string;
  titleInit: string;
  event_name: string;
  defaultEmailSettings: any;
  editorMaximized: boolean;
  titleError: boolean;
  contentError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  defaultMessageId = "";
  currentEmailId: string | undefined;
  detailMessageId: string = "";
  settingsMessageId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      selectedTab: 0,
      headerTabs: [],
      emailSettings: {},
      content: undefined,
      contentPlain: "",
      titleError: false,
      contentError: false,
      title: "",
      titleInit: "",
      event_name: "",
      defaultEmailSettings: {},
      editorMaximized: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.callGetValidationApi();
    // Customizable Area Start
    this.getCurrentEmail(this.getCurrentId());
    this.getDefaultEmailSettings();
    // Customizable Area End
  }

  getDefaultEmailSettings = () => {
    const message = this.generateRequestMessage(
      "/admin/v1/default_email_settings",
      "GET"
    );
    this.defaultMessageId = message.messageId;
    this.send(message);
  };

  getCurrentId = () => (this.props.match?.params?.id as string) || "";

  getCurrentEmail = (id: string, force?: boolean) => {
    if (!force && id === this.currentEmailId) return;
    this.currentEmailId = id;
    if (id === "create") {
      this.setState({
        content: "",
      });
    } else {
      this.props.showLoader();
      const detailMessage = this.generateRequestMessage(
        `${Endpoint}/${this.currentEmailId}`,
        "GET"
      );

      this.detailMessageId = detailMessage.messageId;
      this.send(detailMessage);
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const oldId = prevProps.match.params.id;
    const newId = this.getCurrentId();
    if (oldId !== newId) {
      this.getCurrentEmail(newId);
    }
  }

  saveChanges = () => {
    const isCreate = this.currentEmailId === "create";

    const requestMessage = this.generateRequestMessage(
      isCreate ? Endpoint : `${Endpoint}/${this.currentEmailId}`,
      isCreate ? "POST" : "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        title: this.state.title,
        content: this.state.content,
      })
    );

    this.settingsMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        if (!this.state.contentPlain || this.state.contentPlain === "\n" || !this.state.title) {
          const titleError = !this.state.title;
          const contentError = !this.state.contentPlain || this.state.contentPlain === "\n";
          return this.setState({
            titleError,
            contentError,
          });
        }
        this.saveChanges();
      }
      if (type === "DISCARDCHANGES") {
        window.location.reload();
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.messageSettingsCall(apiRequestCallId, responseJson)
      this.messageDetailCall(apiRequestCallId, responseJson)
      this.messageDefaultCall(apiRequestCallId, responseJson)
    }
  };

  messageSettingsCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.settingsMessageId) {
      if (responseJson.errors) {
        return this.handleError("Error on save", responseJson);
      }
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success" });
      this.props.history.push(`/website-and-emails/email-templates`);
    }
  }

  messageDetailCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.detailMessageId) {
      if (responseJson.errors) {
        return this.handleError("Error on initialization", responseJson);
      }
      this.setState({
        content: responseJson.data.attributes.content,
        contentPlain: responseJson.data.attributes.content,
        titleInit: responseJson.data.attributes.title,
        title: responseJson.data.attributes.title,
        event_name: responseJson.data.attributes.event_name,
      });
      this.props.hideLoader();
    }
  }

  messageDefaultCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.defaultMessageId) {
      if (responseJson.errors) {
        return this.handleError(
          "Error on loading default email settings",
          responseJson
        );
      }
      this.setState({
        defaultEmailSettings: responseJson?.data[0]?.attributes || {},
      });
    }
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    if (this.state.editorMaximized) {
      this.props.showHeaderBar({ type: "whitescreen" });
      window.location.reload();
    }
  }

  handleError = (title: any, responseJson: any) => {
    this.showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab })
  };

  handleTitleChange = (e: any) => {
    this.setState({ title: e.target.value })
    this.setState({ titleError: false })
    this.props.showHeaderBar({ message: "" });
  };

  handleBackButtonClick = () => {
    this.props.history.push("/website-and-emails/email-templates");
  };

  handleEditorChange = (content: string, contentPlain?: string) => {
    this.setState({ content })
    this.setState({ contentPlain })
    this.setState({ contentError: false })
    this.props.showHeaderBar({ message: "" });
  };

  // @ts-ignore
  handleMaximize = (e) => {
    if (e.data === 1) {
      this.setState({ editorMaximized: true })
    } else {
      this.setState({ editorMaximized: false })
    }
  };
}
