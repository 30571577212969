import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withLoader.Web";
import { withToastProps } from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";

// Customizable Area Start
// Customizable Area End

const links = [
  {
    id: "home",
    link: "/",
  },
  {
    id: "order",
    link: "/orders",
  },
  {
    id: "product",
    link: "/products",
  },
  {
    id: "category",
    link: "/inventory/categories",
  },
  {
    id: "brand",
    link: "/inventory/brands",
  },
  {
    id: "user",
    link: "/customers",
  },
  {
    id: "brand setting",
    link: "/website-and-emails/branding",
  },
  {
    id: "image",
    link: "/website-and-emails/image-catalogue",
  },
  {
    id: "email setting",
    link: "/website-and-emails/email-templates",
  },
  {
    id: "static",
    link: "/website-and-emails/static-pages-table",
  },
  {
    id: "coustomer",
    link: "/website-and-emails/customer-feedback",
  },
  {
    id: "store",
    link: "/business-settings/store-details",
  },
  {
    id: "tax",
    link: "/business-settings/taxes",
  },
  {
    id: "shipping",
    link: "/business-settings/shipping",
  },
  {
    id: "payment",
    link: "/business-settings/payment",
  },
  {
    id: "coupon",
    link: "/business-settings/promo-code",
  },
  {
    id: "notifications",
    link: "/business-settings/notifications",
  },
  {
    id: "mobile",
    link: "/business-settings/mobile-app",
  },
  {
    id: "accountoverview",
    link: "/account/overview",
  },
  {
    id: "profile",
    link: "/account/detail",
  },
  {
    id: "adminUsers",
    link: "/account/admin-users",
  },
  {
    id: "plan",
    link: "/account/plan",
  },
];

export const configJSON = require("./config");

export type Props = RouterProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginAPICallID: string = "";
  invalidPasswordError: string = "";
  invalidEmailError: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {};
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        this.messageLoginAPICall(apiRequestCallId, responseJson)
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      if (AlertBodyMessage === "Admin user not found") {
        this.invalidEmailError = AlertBodyMessage;
        this.forceUpdate();
        return;
      }
      if (AlertBodyMessage === "Invalid password") {
        this.invalidPasswordError = AlertBodyMessage;
        this.forceUpdate();
        return;
      }
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
  }

  messageLoginAPICall = (apiRequestCallId: string, responseJson: {
    token: any; admin_user:
      { data: { attributes: { store_name: any; currency_type: string; }; }; }; build_card_id: string;
  }) => {
    if (apiRequestCallId === this.loginAPICallID) {
      this.props.hideLoader();
      if (responseJson && responseJson.token) {
        const token = responseJson?.token;
        const adminUser = responseJson?.admin_user;
        if (token) {
          this.setState(
            {
              token: token,
            },
            () => {
              responseJson?.build_card_id && window.localStorage.setItem("build_card_id", responseJson?.build_card_id);
              window.localStorage.setItem("admintoken", token);
              window.localStorage.setItem(
                "adminuser",
                JSON.stringify(adminUser)
              );

              this.setLocalStorageLoginValues(responseJson)
              const currentPathState =
                // @ts-ignore
                this.props.history?.location?.state?.currentPathState || {};
              const adminuser = window.localStorage.getItem("adminuser");
              const permissions = this.getPermissions(adminuser);
              const filteredLinks = this.getFilteredLinks(permissions);

              this.pushRelatedPage(permissions, filteredLinks, currentPathState)
            }
          );
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  //Login Form
  handleSubmitLogin = (loginData: any) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(loginData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

  handleForgotPassword = (requestData: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotAPiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  private pushRelatedPage(permissions: any, filteredLinks: { id: string; link: string }[], currentPathState: any) {
    this.props?.history?.push(
      permissions?.includes("all") || permissions?.includes("order")
        ? "/"
        : filteredLinks[0]?.link || "/",
      {
        currentPathState,
      }
    )
  }

  private getFilteredLinks(permissions: any) {
    return links.filter((link) => {
      return permissions?.includes(link.id)
    })
  }

  private getPermissions(adminuser: string | null) {
    return adminuser
      ? JSON.parse(adminuser)?.data?.attributes?.permissions
      : []
  }

  private setLocalStorageLoginValues(responseJson: { token: any; admin_user: { data: { attributes: { store_name: any; currency_type: string} } } ; build_card_id: string }) {
    if (responseJson.admin_user.data.attributes?.store_name) {
      window.localStorage.setItem(
        "store_name",
        responseJson.admin_user.data.attributes.store_name
      )
    } else {
      window.localStorage.removeItem("store_name");
    }
    if (responseJson.admin_user.data.attributes.currency_type) {
      window.localStorage.setItem(
        "currency_type",
        responseJson.admin_user.data.attributes.currency_type
      )
    }
  }
}
