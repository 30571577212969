import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { DialogProps } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import lodash from "lodash";
const configJSON = require("../config");

export interface PaymentMethod {
  id: number;
  payment_method: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
}

export type Props = DialogProps & {
  // Customizable Area Start
  showHeader: (obj?: object) => void;
  showLoader:() => void;
  hideLoader: () => void;
  setDialogState: () => void;
  paymentMethods: PaymentMethod[];
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => void;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  method: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

class PaymentMethodsController extends BlockComponent<Props, S, SS> {
  getPaymentMethodsMessageId: string = "";
  updatePaymentMethodMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster)
    ];

    this.state = {
      method: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.initPaymentMethods();
    // Customizable Area End
  }

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.handleApiMessages(apiRequestCallId, responseJson);
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(getName(MessageEnum.ActionMessageFromToasterMessage));
      this.handleToasterResponse(type);
    }
  };

  handleToasterResponse = (type: string) => {
    if (type === "SAVECHANGES") {
      if (this.props.paymentMethods.every((method) => method.active === false)) {
        this.props.setDialogState();
      } else {
        this.props.paymentMethods.forEach((method) => {
          this.updatePaymentMethod(method.id);
        });
      }
    } else if (type === "DISCARDCHANGES") {
      this.initPaymentMethods();
    }
  };

  handleApiMessages = (apiRequestCallId: string, responseJson:any) => {
    if (this.getPaymentMethodsMessageId === apiRequestCallId) {
      if (responseJson?.errors) {
        this.showError(configJSON.errorOnSave, responseJson, this.props.hideLoader(), this.props.setDialogState);
      } else {
        this.props.setPaymentMethods(lodash.orderBy(responseJson, ["id"], ["asc"]));
        this.props.hideLoader();
      }
    } else if (this.updatePaymentMethodMessageId === apiRequestCallId) {
      if (responseJson?.errors) {
        this.showError(configJSON.errorOnSave, responseJson, this.props.hideLoader(), this.props.setDialogState);
      } else {
        this.props.hideLoader();
      }
    }
  };

  initPaymentMethods = () => {
    const requestMessage = this.generateRequestMessage(configJSON.paymentMethodsURL, configJSON.getMethod);
    this.props.showLoader();
    this.getPaymentMethodsMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  updatePaymentMethod = (id: number) => {
    const requestMessage = this.generateRequestMessage(`${configJSON.paymentMethodsURL}/${id}`, configJSON.putMethod);
    const methodData = this.props.paymentMethods.find((method) => method.id === id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({
      id: methodData?.id,
      active: methodData?.active,
    }));
    this.updatePaymentMethodMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  handlePaymentMethodChange = (paymentMethod: PaymentMethod) => {
    paymentMethod.active = !paymentMethod.active;
    this.props.setPaymentMethods([...this.props.paymentMethods]);
    this.props.showHeader();
  };
}

export default PaymentMethodsController;
