import React, { Component } from "react";
import HomeScreen from "../../../components/src/HomeScreen";
import InfoPage from "../../info-page/src/InfoPageBlock";
import AlertBlock from "../../alert/src/AlertBlock.web";
import WebRoutes from "./WebRoutes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/fontastic/styles.css";

const routeMap = {
  Home: {
    component: HomeScreen,
    path: "/",
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage",
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true,
  },
};

class App extends Component {
  render() {
    return <WebRoutes />;
  }
}

export default App;
