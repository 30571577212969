// @ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Icon,
  Theme,
  InputAdornment,
} from "@material-ui/core";
import withDialogBox from "../../studio-store-ecommerce-components/src/HOC/withDialog.web";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import * as YupValidation from "yup";
import { Formik, Form } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import OrderFilterDialogController from "./OrderFilterDialogController";
import TextField from "../../studio-store-ecommerce-components/src/TextField/TextField.web";

const configJSON = require("./config");

export const Schema = YupValidation.object().shape({
  fromDate: YupValidation.date().transform((curr, orig) =>
    orig === "" ? null : curr
  ),
  toDate: YupValidation.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(YupValidation.ref("fromDate"), configJSON.toDateCanTBeBeforeFromDate)
    .when("fromDate", {
      is: (fromDate) => !!fromDate,
      then: YupValidation.date()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .min(
          YupValidation.ref("fromDate"),
          configJSON.toDateCanTBeBeforeFromDate
        )
        .required(configJSON.thisFieldRequired),
    }),
  isPlaced: YupValidation.boolean(),
  isDelivered: YupValidation.boolean(),
  isRefunded: YupValidation.boolean(),
  isCancelled: YupValidation.boolean(),
  fromTotal: YupValidation.number(),
  toTotal: YupValidation.lazy(() =>
    YupValidation.number().when("fromTotal", {
      is: (fromTotal) => !!fromTotal,
      then: YupValidation.number()
        .required(configJSON.thisFieldRequired)
        .min(
          YupValidation.ref("fromTotal"),
          configJSON.toAmountCanTBeLessThanFromAmount
        ),
    })
  ),
});

export class OrderFilterDialog extends OrderFilterDialogController {
  getInitailValues = () => {
    return {
      fromDate: this.props.dataToPass?.initialFilterValues?.fromDate || "",
      toDate: this.props.dataToPass?.initialFilterValues?.toDate || "",
      isPlaced: this.props.dataToPass?.initialFilterValues?.isPlaced
        ? true
        : false,
      isDelivered: this.props.dataToPass?.initialFilterValues?.isDelivered
        ? true
        : false,
      isRefunded: this.props.dataToPass?.initialFilterValues?.isRefunded
        ? true
        : false,
      isCancelled: this.props.dataToPass?.initialFilterValues?.isCancelled
        ? true
        : false,
      fromTotal: this.props.dataToPass?.initialFilterValues?.fromTotal || "",
      toTotal: this.props.dataToPass?.initialFilterValues?.toTotal || "",
    };
  };

  renderFilterByDate = ({
    values,
    touched,
    errors,
    classes,
    expandAccordion,
    setFieldValue,
    setFieldError,
  }) => {
    return (
      <Grid item xs={12}>
        <Accordion
          elevation={0}
          expanded={expandAccordion[0]}
          onChange={this.handleAccordionChange(0)}
        >
          <AccordionSummary
            classes={{
              root: classes.AccordionSummaryRoot,
            }}
            expandIcon={
              <svg
                width="24"
                height="13"
                viewBox="0 0 24 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.25 1.31055L12.53 12.0295C12.3895 12.1702 12.1988 12.2493 12 12.2493C11.8012 12.2493 11.6105 12.1702 11.47 12.0295L0.75 1.31055"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.headingSummary}>
              {configJSON.orderDate}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.AccordionDetailsRoot,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {this.renderFromDatePicker(
                  values,
                  setFieldValue,
                  setFieldError,
                  classes,
                  touched,
                  errors
                )}
              </Grid>
              <Grid item xs={6}>
                {this.renderToDatePicker(
                  values,
                  setFieldValue,
                  setFieldError,
                  classes,
                  touched,
                  errors
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  renderTotalFilter = ({
    expandAccordion,
    values,
    touched,
    errors,
    classes,
    handleBlur,
    handleChange,
  }) => {
    return (
      <Accordion
        elevation={0}
        expanded={expandAccordion[2]}
        onChange={this.handleAccordionChange(2)}
      >
        <AccordionSummary
          classes={{
            root: classes.AccordionSummaryRoot,
          }}
          expandIcon={
            <svg
              width="24"
              height="13"
              viewBox="0 0 24 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.25 1.31055L12.53 12.0295C12.3895 12.1702 12.1988 12.2493 12 12.2493C11.8012 12.2493 11.6105 12.1702 11.47 12.0295L0.75 1.31055"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.headingSummary}>Total</Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.AccordionDetailsRoot,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {this.renderFromTotalInput(
                handleBlur,
                handleChange,
                values,
                touched,
                errors,
                classes
              )}
            </Grid>
            <Grid item xs={6}>
              {this.renderToTotalInput(
                handleBlur,
                handleChange,
                values,
                touched,
                errors,
                classes
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  renderFilterStatus = ({
    expandAccordion,
    classes,
    orderStatus,
    values,
    handleChange,
    handleOrderStatusRefund,
  }) => {
    return (
      <Accordion
        elevation={0}
        expanded={expandAccordion[1]}
        onChange={this.handleAccordionChange(1)}
      >
        <AccordionSummary
          classes={{
            root: classes.AccordionSummaryRoot,
          }}
          expandIcon={
            <svg
              width="24"
              height="13"
              viewBox="0 0 24 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.25 1.31055L12.53 12.0295C12.3895 12.1702 12.1988 12.2493 12 12.2493C11.8012 12.2493 11.6105 12.1702 11.47 12.0295L0.75 1.31055"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.headingSummary}>
            {configJSON.orderStatus}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {this.renderPlacedCheckBox(orderStatus, values, handleChange)}
                  {this.renderDeliveredCheckBox(
                    orderStatus,
                    values,
                    handleChange
                  )}
                  {this.renderRefundedCheckBox(
                    handleOrderStatusRefund,
                    orderStatus,
                    values,
                    handleChange
                  )}
                  {this.renderCancelledCheckBox(
                    orderStatus,
                    values,
                    handleChange
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  private renderToDatePicker(
    values: any,
    setFieldValue: any,
    setFieldError: any,
    classes: any,
    touched: any,
    errors: any
  ) {
    return (
      <div className="date-input">
        <span className="filter-input-label">{configJSON.to}</span>
        <label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableFuture
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              placeholder="dd/mm/yyyy"
              value={values?.toDate || null}
              onChange={(date) => {
                if (isValidDate(date)) {
                  setFieldValue("toDate", date);
                } else {
                  setFieldValue("toDate", "", false);
                  setFieldError("toDate", undefined);
                }
              }}
              className={classes.keyboardDatePicker}
              InputProps={{
                className: classes.input,
              }}
              InputAdornmentProps={{
                className: classes.inputAdornment,
              }}
              KeyboardButtonProps={{
                className: classes.keyboardButton,
              }}
              error={!!(touched?.toDate && errors?.toDate)}
              helperText={
                touched?.toDate && errors?.toDate ? errors?.toDate : ""
              }
            />
          </MuiPickersUtilsProvider>
        </label>
      </div>
    );
  }

  private renderFromDatePicker(
    values: any,
    setFieldValue: any,
    setFieldError: any,
    classes: any,
    touched: any,
    errors: any
  ) {
    return (
      <div className="date-input">
        <span className="filter-input-label">{configJSON.from}</span>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            disableFuture
            disableToolbar
            variant="inline"
            clearable
            placeholder="dd/mm/yyyy"
            value={values.fromDate || null}
            onChange={(date) => {
              if (isValidDate(date)) {
                setFieldValue("fromDate", date);
              } else {
                setFieldValue("fromDate", "", false);
                setFieldError("toDate", undefined);
              }
            }}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            className={classes.keyboardDatePicker}
            InputProps={{
              className: classes.input,
            }}
            InputAdornmentProps={{
              className: classes.inputAdornment,
            }}
            KeyboardButtonProps={{
              className: classes.keyboardButton,
            }}
            error={!!(touched?.fromDate && errors?.fromDate)}
            helperText={
              touched?.fromDate && errors?.fromDate ? errors?.fromDate : ""
            }
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }

  private renderToTotalInput(
    handleBlur: any,
    handleChange: any,
    values: any,
    touched: any,
    errors: any,
    classes: any
  ) {
    return (
      <TextField
        variant="outlined"
        type="number"
        fullWidth
        // @ts-ignore
        width="100%"
        label={configJSON.to}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values?.toTotal}
        name="toTotal"
        error={touched?.toTotal && errors?.toTotal ? true : false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" disableTypography>
              {window.localStorage.getItem("currency_type")}
            </InputAdornment>
          ),
          classes: {
            adornedStart: classes.amountIcon,
          },
        }}
        helperText={touched?.toTotal && errors?.toTotal ? errors?.toTotal : ""}
      />
    );
  }

  private renderFromTotalInput(
    handleBlur: any,
    handleChange: any,
    values: any,
    touched: any,
    errors: any,
    classes: any
  ) {
    return (
      <TextField
        variant="outlined"
        type="number"
        fullWidth
        // @ts-ignore
        width="100%"
        label={configJSON.from}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values?.fromTotal}
        name="fromTotal"
        error={touched?.fromTotal && errors?.fromTotal ? true : false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" disableTypography>
              {window.localStorage.getItem("currency_type")}
            </InputAdornment>
          ),
          classes: {
            adornedStart: classes.amountIcon,
          },
        }}
        helperText={
          touched?.fromTotal && errors?.fromTotal ? errors?.fromTotal : ""
        }
      />
    );
  }

  private renderCancelledCheckBox(
    orderStatus: any,
    values: any,
    handleChange: any
  ) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="19"
                  height="19"
                  rx="4.5"
                  fill="white"
                  stroke="#757575"
                />
              </svg>
            }
            checkedIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="5" fill="#6200EA" />
                <path
                  d="M5 10.949L7.04167 13.6398C7.1988 13.8578 7.45965 13.9918 7.74197 13.9996C8.0243 14.0075 8.29331 13.8881 8.46417 13.6793L15 6"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            className="order-status-checkbox"
            color="primary"
            checked={this.getCheckedStatus(orderStatus, values, "cancelled", "isCancelled")}
            onChange={handleChange}
            name="isCancelled"
          />
        }
        label={configJSON.cancelled}
        className="order-status-item"
        disabled={orderStatus === "all" ? false : true}
      />
    );
  }

  private getCheckedStatus(orderStatus: any, values: any, status, key): boolean | undefined {
    if (orderStatus === status) {
      return true;
    }
    return orderStatus === "all"
        ? values?.[key]
        : false
  }

  private renderRefundedCheckBox(
    handleOrderStatusRefund: any,
    orderStatus: any,
    values: any,
    handleChange: any
  ) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="19"
                  height="19"
                  rx="4.5"
                  fill="white"
                  stroke="#757575"
                />
              </svg>
            }
            checkedIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="5" fill="#6200EA" />
                <path
                  d="M5 10.949L7.04167 13.6398C7.1988 13.8578 7.45965 13.9918 7.74197 13.9996C8.0243 14.0075 8.29331 13.8881 8.46417 13.6793L15 6"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            className="order-status-checkbox"
            color="primary"
            checked={handleOrderStatusRefund(orderStatus, values)}
            onChange={handleChange}
            name="isRefunded"
          />
        }
        label={configJSON.refunded}
        className="order-status-item"
        disabled={orderStatus === "all" ? false : true}
      />
    );
  }

  private renderDeliveredCheckBox(
    orderStatus: any,
    values: any,
    handleChange: any
  ) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="19"
                  height="19"
                  rx="4.5"
                  fill="white"
                  stroke="#757575"
                />
              </svg>
            }
            checkedIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="5" fill="#6200EA" />
                <path
                  d="M5 10.949L7.04167 13.6398C7.1988 13.8578 7.45965 13.9918 7.74197 13.9996C8.0243 14.0075 8.29331 13.8881 8.46417 13.6793L15 6"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            className="order-status-checkbox"
            color="primary"
            checked={this.getCheckedStatus(orderStatus, values, "delivered", "isDelivered")}
            onChange={handleChange}
            name="isDelivered"
          />
        }
        label={configJSON.delivered}
        className="order-status-item"
        disabled={orderStatus === "all" ? false : true}
      />
    );
  }

  private renderPlacedCheckBox(
    orderStatus: any,
    values: any,
    handleChange: any
  ) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="19"
                  height="19"
                  rx="4.5"
                  fill="white"
                  stroke="#757575"
                />
              </svg>
            }
            checkedIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="5" fill="#6200EA" />
                <path
                  d="M5 10.949L7.04167 13.6398C7.1988 13.8578 7.45965 13.9918 7.74197 13.9996C8.0243 14.0075 8.29331 13.8881 8.46417 13.6793L15 6"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            className="order-status-checkbox"
            color="primary"
            checked={this.getCheckedStatus(orderStatus, values, "placed", "isPlaced")}
            onChange={handleChange}
            name="isPlaced"
          />
        }
        label={configJSON.placed}
        className="order-status-item"
        disabled={orderStatus === "all" ? false : true}
      />
    );
  }

  private renderFooterActonButtons(classes: any) {
    return (
      <div className={classes.footerAction}>
        <Button
          variant="text"
          color="primary"
          onClick={this.resetChanges.bind(this)}
        >
          {configJSON.resetAllButton}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            padding: "10px 18px",
            width: "100px",
            fontSize: "12px",
          }}
          type="submit"
        >
          {configJSON.applyButton}
        </Button>
      </div>
    );
  }

  render() {
    const { classes, orderStatus } = this.props;
    const { expandAccordion } = this.state;
    return (
      <Formik
        initialValues={this.getInitailValues()}
        enableReinitialize
        validationSchema={Schema}
        onSubmit={(values) => {
          this.applyChanges(values);
        }}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            setFieldError,
            handleChange,
            handleBlur,
          } = formikProps;
          const handleOrderStatusRefund = (
            orderStatus: string,
            values: any
          ) => {
            let isRefunded = false;
            if (orderStatus === "refunded") {
              isRefunded = true;
            } else if (orderStatus === "all") {
              isRefunded = values?.isRefunded || false;
            }
            return isRefunded;
          };

          return (
            <Form
              noValidate
              style={{ margin: 0 }}
              autoComplete="off"
              translate={undefined}
            >
              <>
                <Grid
                  container
                  className={[classes.root, "customScrollBar"].join(" ")}
                  justifyContent="center"
                >
                  {this.renderFilterByDate({
                    values,
                    touched,
                    errors,
                    classes,
                    expandAccordion,
                    setFieldValue,
                    setFieldError,
                  })}
                  <Grid item xs={12}>
                    {this.renderFilterStatus({
                      expandAccordion,
                      classes,
                      orderStatus,
                      values,
                      handleChange,
                      handleOrderStatusRefund,
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderTotalFilter({
                      expandAccordion,
                      values,
                      touched,
                      errors,
                      classes,
                      handleBlur,
                      handleChange,
                    })}
                  </Grid>
                </Grid>
                {this.renderFooterActonButtons(classes)}
              </>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export const styles = (theme: Theme) =>
  createStyles({
    dialogFooter: {
      "& > *": {
        margin: theme?.spacing(0, 1),
      },
    },
    formControl: {
      paddingLeft: "8px",
    },
    footerAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: "0 0 1",
      gap: "16px",
    },
    amountIcon: {
      color: "#3C3E49",
    },
    root: {
      marginBottom: "0",
      maxHeight: "330px",
      overflowY: "auto",
    },
    AccordionSummaryRoot: {
      padding: "0",
      margin: "0px 24px",
      borderBottom: "1px solid #ECEDF0",
    },
    AccordionDetailsRoot: {
      padding: "8px 24px 16px;",
    },
    headingSummary: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#3C3E49",
    },
    input: {
      padding: 0,
    },
    keyboardDatePicker: {
      "& .MuiOutlinedInput-input": {
        padding: "14.5px 14px",
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        paddingRight: "0",
      },
    },
    inputAdornment: {
      // padding: 2,
    },
    keyboardButton: {
      padding: 5,
    },
  });
const OrderFilterDialogComponent = withStyles(styles)(
  withDialogBox(OrderFilterDialog)
);

export const FilterButton = withDialogBox((props: any) => {
  const openFilterDialog = async () => {
    const filterData = await props.openDialogBox({
      width: "20%",
      renderedComponent: OrderFilterDialog,
      title: "Filter",
      dataToPass: {
        OrderFilter: props.orderFilters,
      },
      disableBackdropClick: true,
      disableEscapeKeyDown: true,
      withCustomDialog: false,
      className: "test-test-tes",
      style: { background: "red" },
    });
    if (filterData) {
      props.setFilter(filterData?.orderFilters);
    }
  };
  return (
    <Grid item xs={2}>
      <Button
        variant="outlined"
        style={{
          padding: "10.5px 15px",
          textTransform: "capitalize",
          borderRadius: 0,
          borderRight: "none",
        }}
        onClick={() => {
          openFilterDialog();
        }}
        endIcon={
          <Icon>
            <KeyboardArrowDownIcon />
          </Icon>
        }
        fullWidth
      >
        {configJSON.filterOrder}
      </Button>
    </Grid>
  );
});

const isValidDate = (date: MaterialUiPickersDate) => {
  // @ts-ignore
  return date instanceof Date && !isNaN(date);
};

export default OrderFilterDialogComponent;
