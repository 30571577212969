import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showHeaderBar: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  token: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  loginUserDetails?: any;
  forgotPasswordEmail: any;
  onboardingResponse: any;
  loading: boolean;
  showOtp: boolean;
  showOtpError: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  otpExpireDate?: Date;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  forgotPasswordApiCallId: string = "";
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      token: "",
      forgotPasswordEmail: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      onboardingResponse: {},
      loading: false,
      showOtp: false,
      showOtpError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
    this.emailReg = /^[^@\s]+@[^\s@]+\.[^\s@]+$/;

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  handleForgotPassword = (requestData: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestData.otp
        ? configJSON.forgotApiWithOtpEndPoint
        : configJSON.forgotAPiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  forgotPassword = async (values: any) => {
    const requestData: any = {
      email: values.email,
    };
    if (this.state.showOtp && !values.otp) {
      return this.setState({ showOtpError: true });
    } else {
      this.setState({ showOtpError: false });
      requestData.otp = values.otp;
    }

    this.setState({
      forgotPasswordEmail: requestData.email,
    });
    return this.handleForgotPassword(requestData);
  };

  resetPassword = (values: any) => {
    const requestData = { ...values };
    delete requestData.email;
    delete requestData.otp;

    this.setState({
      loading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ...requestData, token: this.state.token })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.messageForgotPasswordApiCall(apiRequestCallId, responseJson)
      this.messageResetPasswordApiCall(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  messageForgotPasswordApiCall = (apiRequestCallId: string, responseJson: { token: any; errors: any[]; error: any; messages: any[]; }) => {
    if (apiRequestCallId === this.forgotPasswordApiCallId) {
      if (responseJson.token) {
        // validated otp
        this.setState({
          token: responseJson.token,
          loading: false,
        });
      } else {
        if (responseJson.errors || responseJson.error) {
          this.props.showHeaderBar({
            message: responseJson.error || responseJson.errors[0],
            type: "error",
          });
          this.setState({ loading: false });
        } else {
          this.props.showHeaderBar({
            message: responseJson.messages[0],
            type: "success",
          });

          const otpExpireDate = new Date(Date.now() + 5 * 60 * 1000);
          this.setState({ loading: false, showOtp: true, otpExpireDate });
        }
      }
    }
  }


  messageResetPasswordApiCall = (apiRequestCallId: string, responseJson: { token: any; errors: any[]; error: any; messages: any[]; }) => {
    if (apiRequestCallId === this.resetPasswordApiCallId) {
      if (responseJson.errors || responseJson.error) {
        this.props.showHeaderBar({
          message: responseJson.error || responseJson.errors[0],
          type: "error",
        });
      } else {
        this.props.showHeaderBar({
          type: "success",
          message:
            "password successfully changed. You will be redirected to login page",
        });
        setTimeout(() => {
          // @ts-ignore
          this.props.history.push("/login");
        }, 4000);
      }
      this.setState({ loading: false });
    }

  }

  sendLoginFailMessage() {
    const message: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(message);
  }

  sendLoginSuccessMessage() {
    const message: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    message.addData(getName(MessageEnum.LoginUserName), this.state.email);
    message.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    message.addData(getName(MessageEnum.LoginPassword), this.state.password);
    message.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(message);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const message: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      message.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      message.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(message);
    }
  }

  openInfoPage() {
    const message: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  }

  goToForgotPassword() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(message);
  }

  goToSocialLogin() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
