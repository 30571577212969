/* global HTMLInputElement */
import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { withHeadeActionBarProps } from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import { withLoaderProps } from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    id: string;
  };

interface S {
  // Customizable Area Start
  primaryColor: string;
  brandSettingId: string;
  appName: string;
  androidAppLink: string;
  iosAppLink: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export class AppSettingsFormController extends BlockComponent<Props, S, SS> {
  brandSettingsGetMessageId: string = "";
  brandSettingsPostMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      primaryColor: "#000",
      brandSettingId: "",
      appName: "",
      androidAppLink: "",
      iosAppLink: "",
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getBrandSettings();
  }

  getBrandSettings = () => {
    this.props.displaySpinner();
    const getBrandSettingsData = this.generateRequestMessage("/admin/v1/brand_settings", "GET");
    this.brandSettingsGetMessageId = getBrandSettingsData.messageId;
    runEngine.sendMessage(getBrandSettingsData.id, getBrandSettingsData);
  }

  postBrandSettings = () => {
    this.props.displaySpinner();
    const brandData = {
      primary_color: this.state.primaryColor,
      deeplink_name: this.state.appName,
      android_app_url: this.state.androidAppLink,
      ios_app_url: this.state.iosAppLink,
    }
    const postBrandSettingsData = this.generateRequestMessage(`/admin/v1/brand_settings/${this.state.brandSettingId}`, "PUT");
    postBrandSettingsData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(brandData)
    );
    this.brandSettingsPostMessageId = postBrandSettingsData.messageId;
    runEngine.sendMessage(postBrandSettingsData.id, postBrandSettingsData);
  }

  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.brandSettingsGetMessageId === apiRequestCallId) {
        this.props.hideLoader();
        this.setState({
          brandSettingId: responseJson.data.id,
          primaryColor: responseJson.data.attributes.primary_color,
          appName: responseJson.data.attributes.deeplink_name,
          androidAppLink: responseJson.data.attributes.android_app_url,
          iosAppLink: responseJson.data.attributes.ios_app_url,
        })
      }

      if (this.brandSettingsPostMessageId === apiRequestCallId) {
        this.props.hideLoader();
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.postBrandSettings();

      } else if (type === "DISCARDCHANGES") {
        this.getBrandSettings();

      }
    }
  }

  openToastOnChange = () => {
    this.props.showHeaderBar({ message: "" });
    return true;
  };

  handleCustomPrimaryChange = (color: any) => {
    this.setState({
      primaryColor: color
    });
    this.openToastOnChange();
  };

  handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    variableName: string,
    limit: number = 51
  ) => {
    if (event.target.value.length < limit) {
      this.setState({ [variableName]: event.target.value } as Pick<S, keyof S>);
      this.openToastOnChange();
    } else {
      return false;
    }
  };
}
