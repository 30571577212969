import React from "react";
import PaymentMethodsController from "./PaymentMethodsController";
import withLoader from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import "../styles.css";
import { withDialog } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const configJSON = require("../config");

export class PaymentMethods extends PaymentMethodsController {
  render() {
    return (
      <div>
        <div className="payment-method-container">
          <div className="payment-method-title-container">
            <label className="payment-method-title">{configJSON.enablePaymentMode}</label>
          </div>
          <div className="payment-method-items-container">
            <FormGroup>
              {this.props.paymentMethods?.map((paymentMethod) => (
                <FormControlLabel
                  key={paymentMethod.id}
                  className="payment-method-items"
                  control={
                    <Checkbox
                      data-testid="payment-method-checkbox"
                      checked={paymentMethod.active}
                      onChange={() => this.handlePaymentMethodChange(paymentMethod)}
                    />
                  }
                  label={paymentMethod.payment_method}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default withDialog(withLoader(PaymentMethods));
