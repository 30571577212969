import React from "react"
import MuiTabs from "@material-ui/core/Tabs"
import MuiTab from "@material-ui/core/Tab"

const configJSON = require("../config")

interface TabProps {
  onTabChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  value: any
}

export const Tabs = ({ onTabChange, value }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {/*@ts-ignore */}
      {configJSON.headerTabNames.map((tab, index) => (
        <MuiTab
          data-testid={"mobile-app-tab-" + index}
          key={tab.tab_name}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
          }}
          label={tab.tab_name}
        />
      ))}
    </MuiTabs>
  )
}
