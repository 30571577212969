import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Tab as TabType, EmailSettingsMap } from "./Types";
import { RouterProps } from "react-router";
import { DialogProps } from "../../studio-store-ecommerce-components/src/Dialog/DialogContext";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withToastProps } from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";

export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  selectedTab: number;
  headerTabs: TabType[];
  emailSettings: EmailSettingsMap;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export const Endpoint = "/admin/v1/email_settings";

export default class EmailTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  settingsMessageId: string = "";
  settingsUpdates: string[] = [];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedTab: 0,
      headerTabs: [],
      emailSettings: {},
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
    // Customizable Area Start

    // Customizable Area End
  }

  initPage = () => {
    this.props.displaySpinner();
    const requestMessage = this.generateRequestMessage(Endpoint, "GET");
    this.settingsMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.settingsMessageId === apiRequestCallId) {
        const allEmailSettings = (responseJson.data as TabType[])
          .map((setting) => setting.categories.map((category) => category.email_settings))
          .flat(2);
        const emailSettings: EmailSettingsMap = {};
        allEmailSettings.forEach((setting) => {
          emailSettings[setting.id] = setting;
        });

        this.setState({
          headerTabs: responseJson.data,
          emailSettings,
        });
        this.props.hideLoader();
      }
      if (this.settingsUpdates.includes(apiRequestCallId)) {
        const updateIndex = this.settingsUpdates.indexOf(apiRequestCallId);
        this.settingsUpdates.splice(updateIndex, 1);
        this.setState((prevState) => ({
          emailSettings: {
            ...prevState.emailSettings,
            [Number(responseJson.data.id)]: {
              id: Number(responseJson.data.id),
              ...responseJson.data.attributes,
            },
          },
        }));
        this.props.hideLoader();
      }
    }
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab })
  };

  handleCreateClick = () => {
    return this.props.history.push("email-templates/create");
  };

  handleEditClick = (itemId: number) => {
    return this.props.history.push(`email-templates/${itemId}`);
  };

  handleActiveChange = (itemId: number, newState: boolean) => {
    this.props.displaySpinner();
    const requestMessage = this.generateRequestMessage(`${Endpoint}/${itemId}`, "PATCH");
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ active: newState })
    );
    this.settingsUpdates.push(requestMessage.messageId);
    this.send(requestMessage);
  };
}
