import * as Yup from "yup";

export const PostalCodeValidation = (countryField: string) =>
  Yup.string()
    .required("This field is required")
    .when(countryField, {
      is: (country) => country?.toLowerCase() === "india",
      then: (schema: any) =>
        schema.matches(/^\d{3}\s?\d{3}$/, "Zip code is not valid"),
      otherwise: (schema: any) =>
        schema.when(countryField, {
          is: (country: any) => country?.toLowerCase() === 'uk',
          then: (schema: any) =>
            schema.matches(
              /^((\w{1,2})|([A-Za-z][A-HJ-Ya-hj-y]\d{1,2})|([A-Za-z]\d[A-Za-z])|([A-Za-z][A-HJ-Ya-hj-y]?\d?[A-Za-z])) \d[A-Za-z]{2}$/,
              "Zip code is not valid"
            ),
          otherwise: (schema: any) =>
            schema.matches(
              /((^\d{5}-\d{4}$)|(^\d{5}$)|(^[A-Z]\d[A-Z] \d[A-Z]\d$))/,
              "Zip code is not valid"
            ),
        }),
    });

export const AddressValidation = Yup.string()
  .max(75, "Maximum 75 characters");

export const PhoneValidation = (countryField: string) =>
  Yup.string().when(countryField, {
    is: (country) => country?.toLowerCase() === "india" || country?.toLowerCase() === "united states",
    then: (schema: any) =>
      schema
        .min(10, "Phone must be 10 characters")
        .max(10, "Phone must be 10 characters"),
    otherwise: (schema: any) =>
      schema
        .min(11, "Phone must be 11 characters")
        .max(11, "Phone must be 11 characters"),
  });
